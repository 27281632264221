@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Rowdies:wght@700&display=swap");

Button {
  border: none;
  color: #172a3a;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.btn {
  cursor: pointer;
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
}
