@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");

.coding-journey-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  margin: 10px auto 0;
  max-width: 1000px;
  min-height: 100vh;
}

p.coding-journey-description {
  margin: 20px 0;
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.5;
  color: black;
  font-family: "Barlow", sans-serif;
  max-width: 800px;
}

.learner-journal-button {
  margin-top: 30px;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
