@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");

.cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.card-text {
  font-family: "Barlow", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.tech-stack {
  font-family: "Barlow", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #7a9e9f;
}

.team-links:hover {
  text-decoration: underline;
}

.working-on {
  font-family: "Barlow", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.launch-button {
  font-family: "Barlow", sans-serif;
}
.launch-button:hover {
  background-color: #7a9e9f;
}
