@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Rowdies:wght@700&display=swap");

.headingRight,
.headingLeft {
  font-family: "Rowdies", cursive;
  font-size: 2.5rem;
  color: #172a3a;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

/* Media quieries for headings */
.heading-responsive {
  font-size: 2.5rem; /* Default font size for larger screens */
}

@media (max-width: 768px) {
  .heading-responsive {
    font-size: 2rem; /* Font size for screens up to 768px wide */
  }
}

@media (max-width: 480px) {
  .heading-responsive {
    font-size: 0.8rem; /* Font size for screens up to 480px wide */
  }
}

/* Avatar animation */
@keyframes slideIn {
  0% {
    transform: translateX(-50px);
  }
  50% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(-50px);
  }
}

.leftImage {
  animation: slideIn 3s ease-in-out infinite;
}

.rightImage {
  animation: slideIn 3s ease-in-out infinite;
}
