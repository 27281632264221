@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");

.Footer {
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
  color: #fe5f55;
}

.Footer a {
  text-decoration: underline;
  color: #fe5f55;
  transition: all 100ms ease-in-out;
}

.linkedInIcon {
  width: 25px;
  height: 25px;
  margin: 0 10px;
}

.linkedInIcon:hover,
.githubIcon:hover {
  transform: scale(1.1); /* Example: Increase size on hover */
}

.githubIcon {
  width: 25px;
  height: 25px;
  margin: 0 15px 0 0;
}

