@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Rowdies:wght@700&display=swap");

.heroHeading {
  font-family: "Rowdies", cursive;
  font-size: 2.5rem;
  color: #172a3a;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.heroDescription {
  font-family: "Barlow", sans-serif;
  font-size: 1.5rem;
  color: #172a3a;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: capitalize;
}
