@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rowdies:wght@400&display=swap");

.headings {
  font-family: "Rowdies", cursive;
  font-weight: 100;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.coding-journal-today-progress,
.coding-journal-thoughts {
  font-family: monospace;
  font-size: 14px;
  margin: 10px;
  text-justify: inter-word;
}

.weekly-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  margin: 5px;
  margin-top: 20px;
  border: 1px solid #fcddec;
}

.weekly-summary h1 {
  background-color: white;
  box-shadow: none;
}

.week-description {
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  margin: 2px;
}

.coding-journal-links {
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  margin: 2px;
}

.coding-journal-image {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.coding-journal-links {
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  margin: 2px;
}
